import React from "react";
import {
    convertFormDataToKeyValueWithTouched,
    extractGSTValue,
} from "../../../../../utils/utilities";
import { TableDataType } from "../../../../../V2/POS/POSPanel";
import cloneDeep from "lodash/cloneDeep";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { TenderSplitFormV2 } from "Pages/CashupHome/ExcelTable/TenderSplitItemForm";
import { ClassSplitFormV2 } from "Pages/CashupHome/ExcelTable/ClassSplitItemForm";
import {
    AntDFormState,
    AntDFormStateEftposCountOnly,
    AntDFormStateForGaming,
    AntDFormStateWithSplitFlag,
    KeyValuePair,
    SplitErrorType,
} from "@types";
import { AccountItemSplit, EftposCountV2Item } from "@generated";
import {
    DepositInTransactionInit,
    DepositOutTransactionInit,
    EftposCountTransactionInit,
    InitDataResponseType,
    TenderTypes,
} from "../@types";
import { TableRow, TableRows } from "V2/Table/TableBody";
import { formatDollarField } from "@Constants";
import { FieldData } from "@interfaces";
import { Entity, EntityData, EntityTypes } from "@generated/models/EntityData";
import { PaymentTypeEnum } from "@Constants/enum";

export const AggregateOtherClassItemsValue = (
    classItems: ExtendedClassItem[],
    rowIndex: number,
    tableData: TableDataType[]
) => {
    if (!tableData[rowIndex]) {
        return "0";
    }
    let AggregateValue = 0;
    classItems.forEach((currentClass) => {
        const MATCH = tableData[rowIndex].pos_data.class_split?.find(
            (currentClassItem) => currentClassItem.class_id === currentClass.class_id
        );
        if (MATCH) {
            AggregateValue = AggregateValue + MATCH.amount;
        }
    });
    return formatDollarField(Math.round(AggregateValue * 100) / 100);
};
export const AggregateOtherTenderItemsValue = (
    accountItems: ExtendedAccountItem[],
    rowIndex: number,
    tableData: TableDataType[]
) => {
    if (!tableData[rowIndex]) {
        return "0";
    }

    let AggregateValue = 0;
    accountItems.forEach((currentAccount) => {
        const MATCH = tableData[rowIndex].pos_data.account_split?.find(
            (currentClassItem) =>
                currentClassItem.account_id === currentAccount.account_id
        );
        if (MATCH) {
            AggregateValue = AggregateValue + MATCH.amount;
        }
    });
    return formatDollarField(Math.round(AggregateValue * 100) / 100);
};

export const AggregateTransactions = (
    accountItems: ExtendedAccountItem[],
    rowIndex: number,
    tableData: TableDataType[]
) => {
    if (!tableData[rowIndex]) return 0;
    let AggregatedValue = 0;
    tableData[rowIndex].payment_transactions?.forEach((payment) => {
        accountItems.forEach((account) => {
            const Match = payment.account_split.find(
                (accountSplit) => accountSplit.account_id === account.account_id
            );
            if (Match != undefined) {
                AggregatedValue += Number(Match.amount);
            }
        });
    });
    tableData[rowIndex].deposit_in_transactions?.forEach((payment) => {
        accountItems.forEach((account) => {
            const Match = payment.account_split?.find(
                (accountSplit) => accountSplit.account_id === account.account_id
            );
            if (Match != undefined) {
                AggregatedValue += Number(Match.amount);
            }
        });
    });
    tableData[rowIndex].deposit_out_transactions?.forEach((payment) => {
        accountItems.forEach((account) => {
            const Match = payment.account_split?.find(
                (accountSplit) => accountSplit.account_id === account.account_id
            );
            if (Match != undefined) {
                AggregatedValue += Number(Match.amount);
            }
        });
    });
    tableData[rowIndex].eftpos_count_transactions?.forEach((payment) => {
        accountItems.forEach((account) => {
            const Match = payment.account_split.find(
                (accountSplit) => accountSplit.account_id === account.account_id
            );
            if (Match != undefined) {
                AggregatedValue += Number(Match.amount);
            }
        });
    });
    tableData[rowIndex].transfer_transactions?.forEach((payment) => {
        AggregatedValue += Number(payment.amount);
    });
    tableData[rowIndex].transfer_transactions_to?.forEach((payment) => {
        AggregatedValue += Number(payment.amount);
    });

    return AggregatedValue;
};
export const fetchClassValue = (
    rowIndex: number,
    classID: string,
    tableData: TableDataType[]
) => {
    if (!tableData[rowIndex]) {
        return 0;
    }
    const classMatch = tableData[rowIndex].pos_data?.class_split?.find(
        (classSplitOption) => {
            return classSplitOption.class_id === classID;
        }
    );

    if (classMatch === undefined) {
        return 0;
    }

    return classMatch.amount;
};

export const fetchTenderValue = (
    rowIndex: number,
    accountID: string,
    tableData: TableDataType[]
) => {
    if (!tableData[rowIndex]) {
        return 0;
    }
    const accountMatch = tableData[rowIndex].pos_data?.account_split?.find(
        (classSplitOption) => {
            return classSplitOption.account_id === accountID;
        }
    );

    if (accountMatch === undefined) {
        return 0;
    }

    return accountMatch.amount;
};

/**
 * Update the Sales count field following the change to a Class or Tender split item.
 * @param currentTableData
 * @returns
 */
const updateSalesCountField = (
    currentTableData: InitDataResponseType
): InitDataResponseType => {
    let classSum = 0;

    // We don't wish to override the Sales total field if the user has manually input a value.
    if (currentTableData.pos_data.userHasChanged === true) {
        return currentTableData;
    }

    currentTableData.pos_data.class_split?.forEach((currentClassItem) => {
        classSum += currentClassItem.amount;
    });

    currentTableData.pos_data.amount = formatDollarField(classSum);

    return currentTableData;
};

/**
 * Transaction Change methods below
 *
 */
export const onTenderSplitItemEdit = (
    amount: string,
    accountID: string,
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    let AMOUNT = parseFloat(amount);
    if (isNaN(AMOUNT)) return;
    AMOUNT = formatDollarField(AMOUNT);

    const tableDataClone = cloneDeep(tableData);

    if (tableDataClone[rowIndex].pos_data === undefined) return;

    if (tableDataClone[rowIndex].pos_data.account_split === undefined) {
        tableDataClone[rowIndex].pos_data.account_split = [
            { account_id: accountID, amount: AMOUNT },
        ];
    } else {
        const accountMatchIndex = tableDataClone[
            rowIndex
        ].pos_data.account_split!.findIndex(
            (classItem) => classItem.account_id === accountID
        );

        if (accountMatchIndex === -1) {
            // This means the current Item being edited doesn't exist yet. Add it in!
            tableDataClone[rowIndex].pos_data.account_split = [
                ...tableDataClone[rowIndex].pos_data.account_split!,
                { account_id: accountID, amount: AMOUNT },
            ];
        }
        tableDataClone[rowIndex].pos_data.account_split!.splice(
            accountMatchIndex,
            1,
            {
                account_id: accountID,
                amount: AMOUNT,
            }
        );
    }

    onRowDataChange(rowIndex, tableDataClone);
};

export const onClassSplitModalSubmission = (
    data: KeyValuePair,
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    classesData: ExtendedClassItem[]
) => {
    // TODO Review me
    const tableDataClone = cloneDeep(tableData);
    for (const key in data) {
        const value = data[key];
        const ClassID = classesData.find(
            (currentClassItem) => currentClassItem.name === key
        )?.class_id;

        const roundedValue = formatDollarField(
            isNaN(Number(value)) ? 0 : Number(value)
        );

        if (tableDataClone[rowIndex].pos_data.class_split === undefined) {
            // Edge case - shouldn't be reached outside cases of improper data.
            tableDataClone[rowIndex].pos_data.class_split = [
                { class_id: ClassID!, amount: roundedValue },
            ];
        } else {
            const existingClassIndex = tableDataClone[
                rowIndex
            ].pos_data.class_split?.findIndex(
                (currentAccountSplitItem) =>
                    currentAccountSplitItem.class_id === ClassID!
            );
            if (existingClassIndex === undefined) return;
            if (existingClassIndex !== -1) {
                tableDataClone[rowIndex].pos_data.class_split?.splice(
                    existingClassIndex,
                    1,
                    { class_id: ClassID!, amount: roundedValue }
                );
            } else {
                tableDataClone[rowIndex].pos_data.class_split?.push({
                    class_id: ClassID!,
                    amount: roundedValue,
                });
            }
        }
    }

    tableDataClone[rowIndex] = updateSalesCountField(tableDataClone[rowIndex]);

    onRowDataChange(rowIndex, tableDataClone);
};

export const onTenderSplitModalSubmission = (
    data: KeyValuePair,
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    accountsData: ExtendedAccountItem[]
) => {
    const tableDataClone = cloneDeep(tableData);

    for (const key in data) {
        const value = data[key];
        const AccountID = accountsData.find(
            (currentAccountItem) => currentAccountItem.name === key
        )?.account_id;
        const roundedValue = formatDollarField(
            isNaN(Number(value)) ? 0 : Number(value)
        );

        if (tableDataClone[rowIndex].pos_data.account_split === undefined) {
            // Edge case - shouldn't be reached outside cases of improper data.
            tableDataClone[rowIndex].pos_data.account_split = [
                { account_id: AccountID!, amount: roundedValue },
            ];
        } else {
            const existingAccountIndex = tableDataClone[
                rowIndex
            ].pos_data.account_split?.findIndex(
                (currentAccountSplitItem) =>
                    currentAccountSplitItem.account_id === AccountID!
            );
            if (existingAccountIndex === undefined) {
                continue;
            }

            if (existingAccountIndex !== -1) {
                tableDataClone[rowIndex].pos_data.account_split?.splice(
                    existingAccountIndex,
                    1,
                    { account_id: AccountID!, amount: roundedValue }
                );
            } else {
                tableDataClone[rowIndex].pos_data.account_split?.push({
                    account_id: AccountID!,
                    amount: roundedValue,
                });
            }
        }
    }

    tableDataClone[rowIndex] = updateSalesCountField(tableDataClone[rowIndex]);

    onRowDataChange(rowIndex, tableDataClone);
};

export const resetSalesTotal = (
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataClone = cloneDeep(tableData);
    tableDataClone[rowIndex].pos_data.amount = 0;
    tableDataClone[rowIndex].pos_data.tax_amount = undefined;
    tableDataClone[rowIndex].pos_data.userHasChanged = false;
    onRowDataChange(rowIndex, tableDataClone);
};

export const onSalesTotalUpdate = (
    rowIndex: number,
    formValues: { salesTotal: string; GST: string },
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataClone = cloneDeep(tableData);
    const salesTotal = isNaN(parseFloat(formValues.salesTotal))
        ? 0
        : formatDollarField(parseFloat(formValues.salesTotal));

    if (
        parseFloat(formValues.GST) ===
        extractGSTValue(parseFloat(formValues.salesTotal))
    ) {
        tableDataClone[rowIndex].pos_data.amount = salesTotal;
        tableDataClone[rowIndex].pos_data.tax_amount = undefined;
    } else {
        tableDataClone[rowIndex].pos_data.amount = salesTotal;
        tableDataClone[rowIndex].pos_data.tax_amount = formatDollarField(
            parseFloat(formValues.GST)
        );
    }

    tableDataClone[rowIndex].pos_data.userHasChanged = salesTotal !== undefined;
    onRowDataChange(rowIndex, tableDataClone);
};

export const onBetTypeUpdate = (
    rowIndex: number,
    formValues: { sBet: string; tracksideSales: string },
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataClone = cloneDeep(tableData);
    const sBet = isNaN(parseFloat(formValues.sBet))
        ? 0
        : formatDollarField(parseFloat(formValues.sBet));

    const tracksideSales = isNaN(parseFloat(formValues.tracksideSales))
        ? 0
        : formatDollarField(parseFloat(formValues.tracksideSales));

    tableDataClone[rowIndex].tab_data.bet_types = {
        s_bet: sBet,
        trackside_sales: tracksideSales,
    };

    onRowDataChange(rowIndex, tableDataClone);
};

export const onDepositInTransactionSubmission = (
    data: AntDFormStateWithSplitFlag[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataClone = cloneDeep(tableData);

    const DepositInTransactions: DepositInTransactionInit[] = [];
    data.forEach((currentDepositInTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentDepositInTransaction.formData
        );

        try {
            const DepositInTransaction: DepositInTransactionInit = {
                memo: formData["Note"].value,
                amount: formData["Amount"].value,
            };
            const tenderID = formData["Tender split"].value;

            DepositInTransaction["account_split"] = [
                { account_id: tenderID, amount: formData["Amount"].value },
            ];
            DepositInTransactions.push(DepositInTransaction);
        } catch (e) {
            return;
        }
    });

    tableDataClone[rowIndex].deposit_in_transactions = DepositInTransactions;
    onRowDataChange(rowIndex, tableDataClone);
};

const isArrayEmptyOrUndefined = (currentArray: any[] | undefined) => {
    if (currentArray === undefined) {
        return true;
    } else if (currentArray.length === 0) {
        return true;
    }
    return false;
};

export const onEftposCountTransactionSubmission = (
    data: AntDFormStateEftposCountOnly[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    accountsData: ExtendedAccountItem[],
    classAccounts: ExtendedClassItem[]
) => {
    const tableDataDeepCopy = cloneDeep(tableData);

    const ParsedEftposCountTransactions: EftposCountTransactionInit[] = [];
    data.forEach((currentEftposCountTransaction) => {
        const KeyValueFormFields = convertFormDataToKeyValueWithTouched(
            currentEftposCountTransaction.formData
        );

        const tenderSplitItems: any[] = [];
        const TenderAccounts = accountsData.filter(
            (currentAccount) => currentAccount.tender_type === true
        );

        currentEftposCountTransaction.SplitData.forEach((currentTenderItem) => {
            if (currentTenderItem.value) {
                if (parseFloat(currentTenderItem.value)) {
                    const TenderAccountMatch = TenderAccounts.find(
                        (currentAccount) => {
                            const currentTenderName =
                                extractFieldName(currentTenderItem);
                            return currentAccount.name === currentTenderName;
                        }
                    );
                    if (TenderAccountMatch) {
                        tenderSplitItems.push({
                            account_id: TenderAccountMatch.account_id,
                            amount: parseFloat(currentTenderItem.value),
                        });
                    } else {
                        console.log(
                            currentTenderItem.name,
                            "onEftposCountSubmission NO MATCH"
                        );
                    }
                }
            }
        });

        try {
            const EftposCountTransaction: EftposCountV2Item = {
                expected: parseFloat(
                    KeyValueFormFields["Expected"].value.toString()
                ),
                actual: parseFloat(KeyValueFormFields["Actual"].value.toString()),
                memo: "Eftpos Count",
                account_split: tenderSplitItems,
            };
            // Touched means the user has manually changed the value
            if (KeyValueFormFields["GST"].touched) {
                EftposCountTransaction["tax_amount"] = parseFloat(
                    KeyValueFormFields["GST"].value.toString()
                );
            }

            if (
                isArrayEmptyOrUndefined(
                    currentEftposCountTransaction.ClassSplitData
                ) === false
            ) {
                const classSplitItems: any[] = [];

                currentEftposCountTransaction.ClassSplitData!.forEach(
                    (currentClassItem) => {
                        if (currentClassItem.value) {
                            if (parseFloat(currentClassItem.value)) {
                                const ClassAccountMatch = classAccounts.find(
                                    (currentAccount) => {
                                        if (Array.isArray(currentClassItem.name)) {
                                            return (
                                                currentAccount.name ===
                                                currentClassItem.name[0]
                                            );
                                        } else {
                                            return (
                                                currentAccount.name ===
                                                currentClassItem.name
                                            );
                                        }
                                    }
                                );
                                if (ClassAccountMatch) {
                                    classSplitItems.push({
                                        class_id: ClassAccountMatch.class_id,
                                        amount: parseFloat(currentClassItem.value),
                                    });
                                } else {
                                    console.log(
                                        currentClassItem.name,
                                        "onEftposCountSubmission NO MATCH CLASS"
                                    );
                                }
                            }
                        }
                    }
                );
                EftposCountTransaction["class_split"] = classSplitItems;
                // data[rowIndex].ClassSplitData
            } else {
                EftposCountTransaction["action_account_id"] =
                    KeyValueFormFields["Action"].value;
            }
            ParsedEftposCountTransactions.push(EftposCountTransaction);
        } catch (e) {
            return;
        }
    });
    tableDataDeepCopy[rowIndex].eftpos_count_transactions =
        ParsedEftposCountTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};

export const onDepositOutTransactionSubmission = (
    data: AntDFormStateWithSplitFlag[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataClone = cloneDeep(tableData);

    const DepositOutTransactions: DepositOutTransactionInit[] = [];

    data.forEach((currentDepositInTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentDepositInTransaction.formData
        );

        try {
            const DepositOutTransaction: DepositOutTransactionInit = {
                memo: formData["Note"].value,
                amount: formData["Amount"].value,
            };
            const tenderID = formData["Tender split"].value;
            DepositOutTransaction["account_split"] = [
                { account_id: tenderID, amount: formData["Amount"].value },
            ];
            DepositOutTransactions.push(DepositOutTransaction);
        } catch (e) {
            return;
        }
    });

    tableDataClone[rowIndex].deposit_out_transactions = DepositOutTransactions;

    onRowDataChange(rowIndex, tableDataClone);
};
const isAntdFormForGaming = (
    antdForm: AntDFormState | AntDFormStateForGaming
): antdForm is AntDFormState => {
    return (antdForm as AntDFormState).SplitData === undefined;
};

export const onPaymentTransactionSubmission = (
    data: AntDFormState[] | AntDFormStateForGaming[],
    rowIndex: number,
    accountsData: ExtendedAccountItem[],
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataDeepCopy = cloneDeep(tableData);
    const PaymentTransactions: {
        payment_type_id: number;
        gl_account_id?: number;
        class_id?: string;
        memo: string;
        account_split: Array<AccountItemSplit>;
        tax_amount?: number | undefined;
        amount: number;
        entity?: Entity;
    }[] = [];

    data.forEach((currentPaymentTransaction) => {
        const isDataForGaming = isAntdFormForGaming(currentPaymentTransaction);

        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        const tenderSplitItems: any[] = [];
        const TenderAccounts = accountsData.filter(
            (currentAccount) => currentAccount.tender_type === true
        );
        let chequeNoItem: FieldData | undefined = undefined;

        if (isDataForGaming) {
            chequeNoItem = currentPaymentTransaction.EntityData?.find((item) => {
                if (Array.isArray(item.name)) {
                    return (
                        item.name[0].toString().toLowerCase().trim() === "cheque_no"
                    );
                } else {
                    return item.name.toString().toLowerCase().trim() === "cheque_no";
                }
            });
            if (formData["Tender"] !== undefined) {
                const TenderAccountMatch = TenderAccounts.find(
                    (currentTenderItem) =>
                        currentTenderItem.account_id === formData["Tender"].value
                );
                if (TenderAccountMatch !== undefined) {
                    if (
                        TenderAccountMatch.name === TenderTypes.CHEQUE &&
                        chequeNoItem !== undefined
                    ) {
                        tenderSplitItems.push({
                            account_id: TenderAccountMatch.account_id,
                            amount: parseFloat(formData["Amount"].value),
                            cheque_no: chequeNoItem.value.toString(),
                        });
                    } else {
                        tenderSplitItems.push({
                            account_id: TenderAccountMatch.account_id,
                            amount: parseFloat(formData["Amount"].value),
                        });
                    }
                }
            }
        } else {
            (currentPaymentTransaction as AntDFormState).SplitData.forEach(
                (currentTenderItem) => {
                    if (currentTenderItem.value) {
                        if (parseFloat(currentTenderItem.value)) {
                            const TenderAccountMatch = TenderAccounts.find(
                                (currentAccount) => {
                                    if (Array.isArray(currentTenderItem.name)) {
                                        return (
                                            currentAccount.name ===
                                            currentTenderItem.name[0]
                                        );
                                    } else {
                                        return (
                                            currentAccount.name ===
                                            currentTenderItem.name
                                        );
                                    }
                                }
                            );
                            if (TenderAccountMatch) {
                                if (
                                    chequeNoItem !== undefined &&
                                    chequeNoItem.value !== undefined &&
                                    TenderAccountMatch.name === "Cheque"
                                ) {
                                    tenderSplitItems.push({
                                        account_id: TenderAccountMatch.account_id,
                                        amount: parseFloat(currentTenderItem.value),
                                        cheque_no: chequeNoItem.value.toString(),
                                    });
                                } else {
                                    tenderSplitItems.push({
                                        account_id: TenderAccountMatch.account_id,
                                        amount: parseFloat(currentTenderItem.value),
                                    });
                                }
                            } else {
                                console.log(
                                    currentTenderItem.name,
                                    "onPaymentTransactionSubmission NO MATCH"
                                );
                            }
                        }
                    }
                }
            );
        }
        try {
            let PaymentTransaction: {
                payment_type_id: number;
                gl_account_id?: number;
                class_id?: string;
                memo: string;
                account_split: Array<AccountItemSplit>;
                tax_amount?: number;
                amount: number;
                entity?: Entity;
            } | null = null;
            if (isDataForGaming) {
                PaymentTransaction = {
                    payment_type_id: parseInt(formData["Reason"].value),
                    memo: formData["Description"].value,
                    amount: parseFloat(formData["Amount"].value),
                    account_split: tenderSplitItems,
                };
            } else {
                PaymentTransaction = {
                    payment_type_id: PaymentTypeEnum["Petty cash"],
                    gl_account_id: formData["GL Code"].value,
                    memo: formData["Description"].value,
                    amount: parseFloat(formData["Amount"].value),
                    account_split: tenderSplitItems,
                };
                if (formData["Class"].value) {
                    PaymentTransaction["class_id"] = formData["Class"].value;
                }
            }
            // Touched means the user has manually changed the value
            if (
                (formData["GST"] && formData["GST"].touched) ||
                (formData["GST"] && formData["GST"].value !== undefined)
            ) {
                PaymentTransaction["tax_amount"] = formData["GST"].value;
            }

            //forms not having EntityData, the currentPaymentTransaction.EntityData is undefined
            if (
                currentPaymentTransaction.EntityData !== undefined &&
                currentPaymentTransaction.EntityData.length !== 0
            ) {
                const entityDataItem = currentPaymentTransaction.EntityData.reduce(
                    (result, item) => {
                        if (Array.isArray(item.name)) {
                            return { ...result, [item.name[0]]: item.value };
                        } else {
                            return { ...result, [item.name]: item.value };
                        }
                    },
                    {}
                ) as EntityData;
                const entityItem: Entity = {
                    name: entityDataItem.first_name,
                    entity_type: 2,
                    entity_data: entityDataItem,
                };

                PaymentTransaction["entity"] = entityItem;
            }

            PaymentTransactions.push(PaymentTransaction);
        } catch (e) {
            console.log("error", e);
            return;
        }
    });

    tableDataDeepCopy[rowIndex].payment_transactions = PaymentTransactions;
    onRowDataChange(rowIndex, tableDataDeepCopy);
};
export const onPaymentTransactionRemove = (
    data: AntDFormState[] | AntDFormStateForGaming[],
    rowIndex: number,
    accountsData: ExtendedAccountItem[],
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataDeepCopy = cloneDeep(tableData);

    const PaymentTransactions = data.map((currentPaymentTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        const tenderSplitItems: any[] = [];
        const TenderAccounts = accountsData.filter(
            (currentAccount) => currentAccount.tender_type === true
        );
        const isDataForGaming = isAntdFormForGaming(currentPaymentTransaction);
        let chequeNoItem: FieldData | undefined = undefined;

        if (isDataForGaming) {
            chequeNoItem = currentPaymentTransaction.EntityData?.find((item) => {
                if (Array.isArray(item.name)) {
                    return (
                        item.name[0].toString().toLowerCase().trim() === "cheque_no"
                    );
                } else {
                    return item.name.toString().toLowerCase().trim() === "cheque_no";
                }
            });
            if (formData["Tender"] !== undefined) {
                const TenderAccountMatch = TenderAccounts.find(
                    (currentTenderItem) =>
                        currentTenderItem.account_id === formData["Tender"].value
                );
                if (TenderAccountMatch !== undefined) {
                    if (
                        TenderAccountMatch.name === TenderTypes.CHEQUE &&
                        chequeNoItem !== undefined
                    ) {
                        tenderSplitItems.push({
                            account_id: TenderAccountMatch.account_id,
                            amount: parseFloat(formData["Amount"].value),
                            cheque_no: chequeNoItem.value.toString(),
                        });
                    } else {
                        tenderSplitItems.push({
                            account_id: TenderAccountMatch.account_id,
                            amount: parseFloat(formData["Amount"].value),
                        });
                    }
                }
            }
        } else {
            (currentPaymentTransaction as AntDFormState).SplitData.forEach(
                (currentTenderItem) => {
                    if (currentTenderItem.value) {
                        if (parseFloat(currentTenderItem.value)) {
                            const TenderAccountMatch = TenderAccounts.find(
                                (currentAccount) => {
                                    if (Array.isArray(currentTenderItem.name)) {
                                        return (
                                            currentAccount.name ===
                                            currentTenderItem.name[0]
                                        );
                                    } else {
                                        return (
                                            currentAccount.name ===
                                            currentTenderItem.name
                                        );
                                    }
                                }
                            );
                            if (TenderAccountMatch) {
                                if (
                                    chequeNoItem !== undefined &&
                                    chequeNoItem.value !== undefined &&
                                    TenderAccountMatch.name === "Cheque"
                                ) {
                                    tenderSplitItems.push({
                                        account_id: TenderAccountMatch.account_id,
                                        amount: parseFloat(currentTenderItem.value),
                                        cheque_no: chequeNoItem.value.toString(),
                                    });
                                } else {
                                    tenderSplitItems.push({
                                        account_id: TenderAccountMatch.account_id,
                                        amount: parseFloat(currentTenderItem.value),
                                    });
                                }
                            } else {
                                console.log(
                                    currentTenderItem.name,
                                    "onPaymentTransactionSubmission NO MATCH"
                                );
                            }
                        }
                    }
                }
            );
        }

        let PaymentTransaction: {
            payment_type_id: number;
            gl_account_id?: number;
            memo: string;
            account_split: Array<AccountItemSplit>;
            tax_amount?: number;
            amount: number;
            entity?: Entity;
        } | null = null;
        if (isDataForGaming) {
            PaymentTransaction = {
                payment_type_id: parseInt(formData["Reason"].value),
                memo: formData["Description"].value,
                amount: parseFloat(formData["Amount"].value),
                account_split: tenderSplitItems,
            };
        } else {
            PaymentTransaction = {
                payment_type_id: PaymentTypeEnum["Petty cash"],
                gl_account_id: formData["GL Code"].value,
                memo: formData["Description"].value,
                amount: parseFloat(formData["Amount"].value),
                account_split: tenderSplitItems,
            };
        }

        try {
            if (
                (formData["GST"] && formData["GST"].touched) ||
                (formData["GST"] && formData["GST"].value !== undefined)
            ) {
                PaymentTransaction["tax_amount"] = formData["GST"].value;
            }
        } catch (e) {
            console.log("no GST, form is for gaming");
        }

        if (
            currentPaymentTransaction.EntityData !== undefined &&
            currentPaymentTransaction.EntityData.length !== 0
        ) {
            const entityDataItem = currentPaymentTransaction.EntityData.reduce(
                (result, item) => {
                    if (Array.isArray(item.name)) {
                        return { ...result, [item.name[0]]: item.value };
                    } else {
                        return { ...result, [item.name]: item.value };
                    }
                },
                {}
            ) as EntityData;
            const entityItem: Entity = {
                name: entityDataItem.first_name,
                entity_type: EntityTypes.PERSON,
                entity_data: entityDataItem,
            };

            PaymentTransaction["entity"] = entityItem;
        }

        return PaymentTransaction;
    });

    tableDataDeepCopy[rowIndex].payment_transactions = PaymentTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};

export const onCashCountEdit = (
    amount: string,
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const VALUE = parseFloat(amount);

    if (isNaN(VALUE)) return;
    const tableDataClone = cloneDeep(tableData);

    const currentCashCount = tableDataClone[rowIndex].cash_count;
    currentCashCount.actual = VALUE;

    tableDataClone[rowIndex].cash_count = currentCashCount;
    onRowDataChange(rowIndex, tableDataClone);
};

export const onClassSplitItemEdit = (
    amount: string,
    classID: string,
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    let AMOUNT = parseFloat(amount);
    if (isNaN(AMOUNT)) return;

    AMOUNT = formatDollarField(AMOUNT);
    const tableDataClone = cloneDeep(tableData);

    if (tableDataClone[rowIndex].pos_data === undefined) return;

    if (tableDataClone[rowIndex].pos_data.class_split === undefined) {
        tableDataClone[rowIndex].pos_data.class_split = [
            { class_id: classID, amount: AMOUNT },
        ];
    } else {
        const classMatchIndex = tableDataClone[
            rowIndex
        ].pos_data.class_split!.findIndex(
            (classItem) => classItem.class_id === classID
        );

        if (classMatchIndex === -1) {
            // This means the current Item being edited doesn't exist yet. Add it in!
            tableDataClone[rowIndex].pos_data.class_split = [
                ...tableDataClone[rowIndex].pos_data.class_split!,
                { class_id: classID, amount: AMOUNT },
            ];
        } else {
            tableDataClone[rowIndex].pos_data.class_split!.splice(
                classMatchIndex,
                1,
                {
                    class_id: classID,
                    amount: AMOUNT,
                }
            );
        }
    }

    // Hardcoded to "pos_data" as Classes are only applicable to POS screen.

    tableDataClone[rowIndex] = updateSalesCountField(tableDataClone[rowIndex]);
    onRowDataChange(rowIndex, tableDataClone);
};

/**
 * Column generator methods.
 */

export const generatedDefaultClassSplitItems = (
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    classesData: ExtendedClassItem[],
    currentRow: number,
    imbalanceFlag: SplitErrorType,
    submitted: boolean
) => {
    const ClassSplitColumns: TableRows = [];
    const OtherClassSplitItems: ExtendedClassItem[] = [];
    classesData
        .filter(
            (currentClassItem) =>
                currentClassItem.name.toLowerCase().trim() !== "gaming"
        )
        .forEach((currentClass) => {
            const normalisedString = currentClass.name.toLowerCase().trim();
            // Note: In future instead of doing a strict string check an array of IDs will be passed to specify which Class Items need to be their own column and which should be in the "Others" section
            if (normalisedString === "food") {
                ClassSplitColumns.push({
                    onSubmit: (data) =>
                        onClassSplitItemEdit(
                            data,
                            currentClass.class_id,
                            currentRow,
                            tableData,
                            onRowDataChange
                        ),
                    value: fetchClassValue(
                        currentRow,
                        currentClass.class_id,
                        tableData
                    ),
                    readOnly: submitted,
                });
            } else if (normalisedString === "beverage") {
                ClassSplitColumns.push({
                    onSubmit: (data) =>
                        onClassSplitItemEdit(
                            data,
                            currentClass.class_id,
                            currentRow,
                            tableData,
                            onRowDataChange
                        ),
                    value: fetchClassValue(
                        currentRow,
                        currentClass.class_id,
                        tableData
                    ),
                    readOnly: submitted,
                });
            } else {
                OtherClassSplitItems.push(currentClass);
            }
        });
    ClassSplitColumns.push({
        modalTitle: "Other classes",

        value: AggregateOtherClassItemsValue(
            OtherClassSplitItems,
            currentRow,
            tableData
        ),
        editModal: (closeModal) => (
            <ClassSplitFormV2
                onModalClose={closeModal}
                onSubmit={(data) =>
                    onClassSplitModalSubmission(
                        data,
                        currentRow,
                        tableData,
                        onRowDataChange,
                        classesData
                    )
                }
                OtherClassSplitItems={OtherClassSplitItems}
                ClassSplitData={
                    tableData[currentRow]
                        ? tableData[currentRow].pos_data.class_split
                        : undefined
                }
                disabled={submitted}
            />
        ),
        hideEditModalFooter: true,
    });
    if (imbalanceFlag === "CLASS" || imbalanceFlag === "BOTH") {
        return ClassSplitColumns.map((currentSplitItem) => {
            return { ...currentSplitItem, errorHighlighting: true } as TableRow;
        });
    }

    return ClassSplitColumns;
};

export const generatedCashTenderItem = (
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    accountsData: ExtendedAccountItem[],
    currentRow: number,
    imbalanceFlag: SplitErrorType,
    submitted: boolean
) => {
    const AccountSplitColumns: TableRows = [];
    const cashAccount = accountsData.find(
        (currentAccount) =>
            currentAccount.tender_type === true &&
            currentAccount.name.toLowerCase().trim() === "cash"
    );
    if (cashAccount !== undefined) {
        AccountSplitColumns.push({
            onSubmit: (data) =>
                onTenderSplitItemEdit(
                    data,
                    cashAccount.account_id,
                    currentRow,
                    tableData,
                    onRowDataChange
                ),
            value: fetchTenderValue(currentRow, cashAccount.account_id, tableData),
            readOnly: submitted,
        });
    }
    if (imbalanceFlag === "TENDER") {
        return AccountSplitColumns.map((currentSplitItem) => {
            return { ...currentSplitItem, errorHighlighting: true } as TableRow;
        });
    }
    return AccountSplitColumns;
};

export const generatedDefaultTenderSplitItems = (
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    accountsData: ExtendedAccountItem[],
    currentRow: number,
    imbalanceFlag: SplitErrorType,
    submitted: boolean
) => {
    const AccountSplitColumns: TableRows = [];
    const OtherTenderSplitItems: ExtendedAccountItem[] = [];
    accountsData
        .filter(
            (currentAccount) =>
                (currentAccount.tender_type === true ||
                    currentAccount.name.toLowerCase().trim() === "eftpos") &&
                !currentAccount.payout_tender_type
        )
        .forEach((currentAccount) => {
            const normalisedString = currentAccount.name.toLowerCase().trim();
            if (normalisedString === "eftpos") {
                AccountSplitColumns.push({
                    onSubmit: (data) =>
                        onTenderSplitItemEdit(
                            data,
                            currentAccount.account_id,
                            currentRow,
                            tableData,
                            onRowDataChange
                        ),
                    value: fetchTenderValue(
                        currentRow,
                        currentAccount.account_id,
                        tableData
                    ),
                    readOnly: submitted,
                });
            } else if (normalisedString === "visa") {
                AccountSplitColumns.push({
                    onSubmit: (data) =>
                        onTenderSplitItemEdit(
                            data,
                            currentAccount.account_id,
                            currentRow,
                            tableData,
                            onRowDataChange
                        ),
                    value: fetchTenderValue(
                        currentRow,
                        currentAccount.account_id,
                        tableData
                    ),
                    readOnly: submitted,
                });
            } else {
                if (normalisedString !== "cash") {
                    OtherTenderSplitItems.push(currentAccount);
                }
            }
        });
    AccountSplitColumns.push({
        modalTitle: "Other tenders",
        value: AggregateOtherTenderItemsValue(
            OtherTenderSplitItems,
            currentRow,
            tableData
        ),
        editModal: (closeModal) => (
            <TenderSplitFormV2
                onModalClose={closeModal}
                onSubmit={(data) =>
                    onTenderSplitModalSubmission(
                        data,
                        currentRow,
                        tableData,
                        onRowDataChange,
                        accountsData
                    )
                }
                OtherTenderSplitItems={OtherTenderSplitItems}
                TenderSplitData={
                    tableData[currentRow]
                        ? tableData[currentRow].pos_data.account_split
                        : undefined
                }
                disabled={submitted}
            />
        ),
        hideEditModalFooter: true,
    });

    if (imbalanceFlag === "TENDER" || imbalanceFlag === "BOTH") {
        return AccountSplitColumns.map((currentSplitItem) => {
            return { ...currentSplitItem, errorHighlighting: true } as TableRow;
        });
    }

    return AccountSplitColumns;
};

export const extractFieldName = (value: FieldData): string => {
    if (Array.isArray(value.name)) {
        return value.name[0].toString();
    } else {
        return value.name.toString();
    }
};

export const positiveNumberValidator = (value: string | number) =>
    Number(value) >= 0;
